import React from "react";
import styled from "styled-components";
import theme from "../../ui/theme";
import BackgroundImage from "gatsby-background-image";
import logo from "../../images/logo.svg";
import { Link } from "gatsby";

const StyledBackgroundImage = styled(BackgroundImage)`
  position: relative;
  width: 54%;
  background: center top cover repeat-y #0a203a;
  .heading-blurb {
    position: absolute;
    right: 0;
    bottom: 106px;
    width: 80%;
    padding: 38px 30px;
    background: rgba(0, 0, 0, 0.8);
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    color: #ffffff;
    h3 {
      margin: 0 0 0.5rem;
      padding: 0;
      color: #ffffff;
      font-size: 36px;
      line-height: 49px;
      span {
        color: #3ac0a4;
      }
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
  img {
    width: 260px;
    margin-left: 40px;
    margin-top: 20px;
  }
  @media all and (max-width: ${theme.breakpoints.lg}) {
    .heading-blurb {
      width: 100%;
      top: 70px;
      bottom: auto;
    }
  }
  @media all and (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    .heading-blurb {
      position: static;
      width: 88%;
      padding: 10px;
      margin: 30px auto 15px;
      h3 {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 10px;
        text-align: center;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    img {
      display: block;
      width: 190px;
      margin: 13px auto 0;
    }
  }
`;

const SignUpFormBackground = ({ fluid }) => (
  <StyledBackgroundImage fluid={fluid}>
    <Link to="/">
      <img src={logo} alt="" />
    </Link>
    <div className="heading-blurb">
      <h3>
        High-Paying Opportunities for <span>Travel Nurses</span>
      </h3>
      <p>
        Travel nurses can earn a great salary at top-tier hospitals across the
        country. See Where You Can Go Today!
      </p>
    </div>
  </StyledBackgroundImage>
);
export default SignUpFormBackground;
